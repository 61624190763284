import React, { useState, useEffect } from "react"
import FigureBox from "../components/figure"
import { Container } from "react-bootstrap"
import Buttons from "../components/button"
import Layout from "../components/layout"
import Title from "../components/title"
import SEO from "../components/seo"
import ScrollAnimation from "react-animate-on-scroll"

const Details = props => {
  const [data, setData] = useState({})

  useEffect(() => {
    console.log(props.location.state)

    setData(props.location.state)
  }, [props])

  return (
    <Layout className="portfolio-details">
      <SEO title="Work Details" />
      <Title
        isDetails={true}
        title={data.title}
        description={data.description}
        client={data.client}
      />
      <Container>
        <div className="details">
          {data.detailImg !== undefined &&
            data.detailImg.map(items => {
              return (
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  offset={90}
                >
                  <FigureBox data={items} isDetails={true} />
                </ScrollAnimation>
              )
            })}
        </div>
        <Buttons>More Works</Buttons>
      </Container>
    </Layout>
  )
}

export default Details
